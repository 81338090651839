import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const EmojiPicker =(props) => {
  const { onEmojiSelect } = props;

  return (
    <>
          <Picker data={data} onEmojiSelect={onEmojiSelect} />
    </>
  );
};

export default EmojiPicker;
